.header-md::after {
	content: none;
}

b {
	font-weight: 500 !important;
}

ion-toolbar {
	border-bottom: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
	box-shadow: none;
}

ion-tab-bar {
	padding-top: 8px;
	padding-bottom: 8px;
	border-top: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))) !important;
}

ion-col {
	margin-bottom: 16px;
	padding: 0;
}

ion-title {
	color: #000000;
}

ion-back-button {
	color: #000000;
}

ion-segment {
	border-bottom: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
}

ion-list.list-md {
	padding-top: 0;
	padding-bottom: 0;
}

ion-card {
	margin-inline: unset !important;
	margin-bottom: 16px;
}

ion-card-title {
	display: flex;
	flex-direction: row;
	align-items: center;
}

ion-item {
	--background: transparent;
	position: relative;
	overflow: unset !important;
}

ion-badge {
	transform: translateY(3px);
	display: inline-flex;
	flex-direction: row;
	align-items: center;
}

button.disabled {
	pointer-events: none !important;
	opacity: 0.5 !important;
}

.menu-button ion-icon {
	color: #000000;
}

.popover-content {
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
}

.action-sheet-title {
	margin-bottom: 40px;
	color: #000000 !important;
	font-size: 20px !important;
	font-weight: 500;
	border-top: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
}

.action-sheet-sub-title {
	font-size: 16px;
	font-weight: normal;
}

.action-sheet-button-inner {
	color: #000000 !important;
}

.action-sheet-icon {
	color: var(--ion-color-primary) !important;
}

.action-sheet-cancel {
	margin-bottom: 20px;
}

.action-sheet-cancel .action-sheet-icon {
	color: var(--ion-color-danger) !important;
}

.action-sheet-cancel .action-sheet-button-inner {
	color: var(--ion-color-danger) !important;
}

.searchbar-input {
	box-shadow: none !important;
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))) !important;
}

.ion-margin-none {
	margin-top: 0 !important;
	margin-right: 0 !important;
	margin-bottom: 0 !important;
	margin-left: 0 !important;
}

.ion-margin-top {
	margin-top: 16px !important;
}

.ion-margin-right {
	margin-right: 16px !important;
}

.ion-margin-bottom {
	margin-bottom: 16px !important;
}

.ion-margin-left {
	margin-left: 16px !important;
}

.ion-margin-top-half {
	margin-top: 8px !important;
}

.ion-margin-right-half {
	margin-right: 8px !important;
}

.ion-margin-bottom-half {
	margin-bottom: 8px !important;
}

.ion-margin-left-half {
	margin-left: 8px !important;
}

.ion-padding-none {
	padding: 0 !important;
	-webkit-padding-start: 0 !important;
	-webkit-padding-end: 0 !important;
}

.ion-padding-top {
	padding-top: 16px !important;
}

.ion-padding-right {
	padding-right: 16px !important;
}

.ion-padding-bottom {
	padding-bottom: 16px !important;
}

.ion-padding-left {
	padding-left: 16px !important;
}

.ion-padding-top-half {
	padding-top: 8px !important;
}

.ion-padding-right-half {
	padding-right: 8px !important;
}

.ion-padding-bottom-half {
	padding-bottom: 8px !important;
}

.ion-padding-left-half {
	padding-left: 8px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-top: 0 !important;
	margin-bottom: 16px !important;
}

.ticket-inner-wrap,
.inner-wrap {
	text-decoration: none;
	display: block;
	padding: 16px 0px 16px 0px;
	width: 100%;
}

.ticket-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.marker-inner-wrap {
	position: relative;
}

.marker-title {
	position: absolute;
	left: 50%;
	transform: translateX(-50%) translateY(-10px);
}

.marker-title p {
	margin-bottom: 0 !important;
}

.marker-inner-wrap p.map {
	font-weight: 500;
	color: #000000;
	font-size: 16px;
	text-shadow: -2px 0 #ffffff, 0 2px #ffffff, 2px 0 #ffffff, 0 -2px #ffffff;
	margin-bottom: 0;
	min-width: 120px;
}

.marker-inner-wrap p.sat {
	font-weight: 500;
	color: #ffffff;
	font-size: 16px;
	text-shadow: -2px 0 #000000, 0 2px #000000, 2px 0 #000000, 0 -2px #000000;
	margin-bottom: 0;
	min-width: 120px;
}

.marker-inner-wrap p:nth-child(2) {
	font-size: 14px;
}

.marker-inner-wrap a {
	text-decoration: none;
	text-align: center;
	display: block;
}

.marker-status {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: 10px;
}

.marker-icon {
	font-size: 24px;
}

.ion-border {
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
}

.ion-border-bottom {
	border-bottom: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
}

.ion-border-radius {
	border-radius: 4px;
}

.mapboxgl-ctrl {
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12) !important;
	border: 1px solid #e0e0e0;
}

.mapboxgl-ctrl.overflow-hidden {
	overflow: hidden;
}

.mapboxgl-ctrl-group button + button {
	border-top: 1px solid #e0e0e0 !important;
}

.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-zoom-out {
	width: 30px !important;
	height: 30px !important;
}

.mapboxgl-ctrl-zoom-in span {
	background-image: url("./assets/add-light-mode.svg") !important;
	background-size: 25px 25px;
}

.mapboxgl-ctrl-zoom-out span {
	background-image: url("./assets/remove-light-mode.svg") !important;
	background-size: 25px 25px;
}

.mapboxgl-ctrl-geolocate-active span {
	background-image: url("./assets/locate-light-mode.svg") !important;
	background-size: cover;
	opacity: 1 !important;
}

.mapboxgl-ctrl-geolocate {
	width: 40px !important;
	height: 40px !important;
}

.mapboxgl-ctrl-geolocate span {
	background-image: url("./assets/locate-light-mode.svg") !important;
	background-size: 30px 30px;
	opacity: 0.5;
}

.mapboxgl-ctrl-map-style {
	width: 40px !important;
	height: 40px !important;
	overflow: hidden;
	border-radius: 4px;
}

.mapboxgl-ctrl-map-style p {
	position: absolute;
	top: calc(100% - 14px);
	left: 50%;
	transform: translateX(-50%);
	font-weight: 500;
	color: #ffffff;
	font-size: 10px;
	margin-bottom: 0 !important;
	width: 100%;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.4);
	height: 14px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.mapboxgl-ctrl-map-style .mapboxgl-ctrl-logo {
	display: none !important;
}

.mapboxgl-ctrl-map-style .mapboxgl-control-container {
	display: none !important;
}

.mapboxgl-ctrl-logo {
	position: relative;
}

.map-marker.primary path {
	fill: var(--ion-color-primary) !important;
}

.map-marker.warning path {
	fill: var(--ion-color-warning) !important;
}

.map-marker.danger path {
	fill: var(--ion-color-danger) !important;
}

.places-map .mapboxgl-ctrl-bottom-left {
	bottom: 24px !important;
	margin: 0 !important;
	left: 16px !important;
}

.places-map .mapboxgl-ctrl-bottom-right {
	bottom: 24px !important;
	margin: 0 !important;
	right: 16px !important;
}

.place-card-map .mapboxgl-ctrl-bottom-left {
	bottom: 16px !important;
	margin: 0 !important;
	left: 16px !important;
}

.place-card-map .mapboxgl-ctrl-bottom-right {
	bottom: 16px !important;
	margin: 0 !important;
	right: 16px !important;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl,
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
	background-color: transparent !important;
	border: none !important;
	box-shadow: none !important;
	margin: 0 !important;
}

.marker-list-indicator {
	padding: 16px;
	position: relative;
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.marker-list-indicator p {
	font-size: 16px;
	color: #fff;
	margin-bottom: 0 !important;
	font-weight: bold;
	z-index: 111;
	position: relative;
	line-height: 1;
}

.marker-list-indicator:before {
	content: "";
	position: absolute;
	width: 100%;
	left: 50%;
	top: 50%;
	padding-bottom: 100%;
	transform: translateY(-50%) translateX(-50%);
	background-color: var(--ion-color-danger);
	border-radius: 100%;
	z-index: 11;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12) !important;
}

.marker-list-modal .modal-wrapper {
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12) !important;
}

.marker-list-modal .modal-wrapper .ion-page {
	padding: 0 16px 16px 16px;
}

.marker-list-modal .modal-wrapper .ion-page .modal-inner-wrap {
	max-height: calc(100% - 52px);
	overflow-y: scroll;
	max-width: 100%;
	overflow-x: hidden;
}

.icon-wrap {
	background-color: var(--ion-color-danger);
	border-radius: 100%;
	width: 40px;
	height: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 16px !important;
	color: #ffffff;
	text-transform: uppercase !important;
}

.icon-wrap.primary {
	background-color: var(--ion-color-primary);
}

.icon-wrap.success {
	background-color: var(--ion-color-success);
}

.icon-wrap.warning {
	background-color: var(--ion-color-warning);
}

.file-input-container {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.file-input-wrap {
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	position: relative;
}

.file-input-wrap p {
	white-space: nowrap;
	width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.attachment-link {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-decoration: none;
	color: unset;
}

.comment-date,
.contact-title {
	opacity: 0.5;
	font-size: 12px;
}

.ion-avatar-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-avatar {
	background-color: var(--ion-color-primary);
	width: 120px;
	height: 120px;
	border-radius: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ffffff !important;
	text-transform: uppercase;
}

.ticket-status-time {
	font-size: 14px;
	font-weight: normal;
	opacity: 0.7;
}

.ticket-place-popover .popover-content {
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
	position: absolute;
	top: 0;
	left: 0;
}

ol {
	padding-left: 16px;
}

ol li {
	padding-left: 16px;
}

.MuiPaper-root {
	color: unset !important;
	background-color: transparent !important;
}

.MuiAccordion-root {
	margin: 0 !important;
	box-shadow: none !important;
}

.MuiAccordion-root::before {
	content: none !important;
}

.MuiAccordionSummary-root {
	min-height: unset !important;
	padding: 0 !important;
}

.MuiAccordionDetails-root {
	display: block !important;
	padding: 0 !important;
}

.MuiAccordionSummary-content {
	margin: 0 !important;
}

.MuiIconButton-edgeEnd {
	margin-right: 4px !important;
}

.loading,
.loading ion-backdrop {
	pointer-events: none;
}

.loading .loading-wrapper {
	box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
	background-color: #ffffff;
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
	color: #000000;
	pointer-events: all;
}

.profile {
	background-color: var(--ion-color-primary);
	border-radius: 100%;
	width: 40px;
	height: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ffffff;
}

.no-connection-notice {
	text-align: center;
	border-bottom: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
	padding: 4px;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.file-upload {
	position: relative;
	display: flex;
}

.file-upload textarea {
	width: calc(100% - 56px) !important;
}

.file-upload-button {
	position: absolute;
	top: 50%;
	transform: translateY(-60%);
	right: 0;
}

.image-upload-button {
	position: absolute;
	top: 50%;
	right: 0;
}

label {
	font-weight: 500;
	font-size: 14px !important;
	position: relative;
	width: unset !important;
}

textarea,
input[type="text"],
input[type="password"] {
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))) !important;
	border-radius: 4px !important;
	padding: 12px 16px !important;
	font-size: 16px;
	outline: none;
	background-color: transparent;
	width: 100% !important;
	position: relative;
	box-shadow: none;
	-webkit-appearance: none;
}

.input-error-text {
	position: absolute;
	font-size: 12px;
	left: 0;
	bottom: -8px;
}

ion-label textarea:disabled,
ion-label input:disabled,
ion-label select:disabled,
.login input:disabled,
.ticket-step-input select:disabled,
.ticket-step-input textarea:disabled,
.ticket-step-input input:disabled {
	color: #a3a2a2 !important;
}

.login {
	padding: 16px;
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.login input[type="text"],
.login input[type="password"] {
	margin-bottom: 16px;
}

.login-wrap {
	width: 100%;
	max-width: 320px;
}

.login p {
	font-size: 12px;
}

.pw-wrap {
	position: relative;
}

.pw-wrap ion-button {
	position: absolute;
	top: 0;
	right: 0;
}

.MuiTouchRipple-root {
	display: none;
}

.attachment-thumbnail {
	background-color: var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
}

.build-info {
	z-index: 111;
	font-size: 12px;
	pointer-events: none;
	opacity: 0.4 !important;
	padding: 8px 16px;
}

.build-info p {
	margin-bottom: 0 !important;
	pointer-events: none;
}

.fm-service-container {
	pointer-events: none;
	opacity: 0;
	transition: all 0s;
	transform: translateY(20px);
}

.fm-service-container.is-active {
	opacity: 1;
	pointer-events: all;
	transform: translateY(0px);
	transition: all 0.35s ease-in-out;
}

.monitoring-section {
	background-color: rgba(215, 216, 218, 0.35);
	border-radius: 4px;
}

.monitoring-section p {
	margin-bottom: 0 !important;
}

.MuiDivider-root {
	background-color: var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))) !important;
}

.alert-wrapper {
	box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24) !important;
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
	padding: 16px;
}

.alert-wrapper .alert-head {
	padding: 0;
}

.alert-wrapper .alert-message {
	margin-bottom: 16px;
	padding: 0;
}

.alert-wrapper .alert-button-group {
	padding: 0;
}

.alert-wrapper .alert-button-group button:last-child {
	margin-right: 0;
}

.alert-wrapper .alert-head h2.alert-title {
	margin-bottom: 0 !important;
}

.alert-wrapper .alert-radio-label.sc-ion-alert-md {
	text-overflow: clip;
}

.logo-white {
	position: absolute;
	opacity: 0;
}

.checkbox-wrap {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.checkbox-wrap ion-checkbox {
	margin-top: 0;
	margin-bottom: 0;
	flex-shrink: 0;
}

ion-content::part(scroll) {
	touch-action: pan-y pinch-zoom !important;
}

.MuiAccordionSummary-content {
	width: 100%;
}

.task-accordion-summary .MuiAccordionSummary-content {
	display: flex !important;
	flex-direction: column;
}

.task-accordion-summary .MuiAccordionSummary-expandIcon {
	position: absolute;
	right: 0;
}

.task-timestamp-wrap {
	width: 100%;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
}

.timestamp-icon-wrap {
	display: flex;
	margin-bottom: 8px;
}

.timestamp-icon-wrap:last-child {
	margin-bottom: 0;
}

.task-title {
	width: calc(100vw - 84px);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-bottom: 16px;
}

.MuiTableRow-root {
	text-decoration: none;
	width: 100%;
}

.MuiTableCell-body,
.MuiTableCell-head {
	line-height: 1;
	color: #000000 !important;
	border-bottom: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))) !important;
}

.MuiTableRow-root .MuiTableCell-body {
	padding: 0 !important;
}

.MuiTableCell-body a {
	font-size: 16px !important;
	padding: 16px;
	display: block;
	color: unset !important;
	text-decoration: none;
}

.responsive-iframe-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe-container .responsive-iframe {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
/*
* Dark Mode
* -------------------------------------------
*/

body.dark .logo-black {
	position: absolute;
	opacity: 0;
}

body.dark .logo-white {
	position: relative;
	opacity: 1;
}

body.dark ion-title {
	color: #ffffff;
}

body.dark .menu-button ion-icon {
	color: #ffffff;
}

body.dark ion-back-button {
	color: #ffffff;
}

body.dark .mapboxgl-ctrl {
	background: #000000;
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07))));
}

body.dark .mapboxgl-ctrl-group button + button {
	border-top: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))) !important;
}

body.dark .mapboxgl-ctrl-zoom-in span {
	background-image: url("./assets/add-dark-mode.svg") !important;
}

body.dark .mapboxgl-ctrl-zoom-out span {
	background-image: url("./assets/remove-dark-mode.svg") !important;
}

body.dark .mapboxgl-ctrl-geolocate-active span {
	background-image: url("./assets/locate-dark-mode.svg") !important;
}

body.dark .mapboxgl-ctrl-geolocate span {
	background-image: url("./assets/locate-dark-mode.svg") !important;
}

body.dark .action-sheet-title {
	color: #ffffff !important;
}

body.dark .action-sheet-button-inner {
	color: #ffffff !important;
}

body.dark .loading .loading-wrapper {
	background-color: #000000;
}

body.dark .loading .loading-wrapper .loading-content {
	color: #ffffff !important;
}

body.dark .no-connection-notice {
	color: #ffffff !important;
}

body.dark ion-label textarea:disabled,
body.dark ion-label input:disabled,
body.dark ion-label select:disabled,
body.dark .login input:disabled,
body.dark .ticket-step-input select:disabled,
body.dark .ticket-step-input textarea:disabled,
body.dark .ticket-step-input input:disabled,
body.dark .row-input select:disabled,
body.dark .row-input textarea:disabled,
body.dark .row-input input:disabled {
	color: rgba(255, 255, 255, 0.4) !important;
}

body.dark .MuiTableCell-body,
body.dark .MuiTableCell-head {
	color: #ffffff !important;
}

body.dark .MuiTableContainer-root {
	border: 1px solid var(--ion-tab-bar-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.07)))) !important;
}
