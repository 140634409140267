/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Import Roboto family **/
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 300;
	font-display: swap;
	src: url("../assets/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 400;
	font-display: swap;
	src: url("../assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src: url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 500;
	font-display: swap;
	src: url("../assets/fonts/Roboto/Roboto-MediumItalic.ttf") format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "Roboto";
	font-style: italic;
	font-weight: 700;
	font-display: swap;
	src: url("../assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
}

/** Ionic CSS Variables **/
:root {
	--ion-font-family: "Roboto", sans-serif;
	--ion-grid-padding: 16px;
	--offset-top: 300px;
	--ion-background-color: #ffffff;
	--ion-tab-bar-background: #ffffff;

	--ion-color-primary: #1469ff;
	--ion-color-primary-rgb: 20, 105, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #125ce0;
	--ion-color-primary-tint: #2c78ff;

	--ion-color-secondary: #008094;
	--ion-color-secondary-rgb: 0, 128, 148;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #007182;
	--ion-color-secondary-tint: #1a8d9f;

	--ion-color-tertiary: #5c61ff;
	--ion-color-tertiary-rgb: 92, 97, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #5155e0;
	--ion-color-tertiary-tint: #6c71ff;

	--ion-color-success: #1c8745;
	--ion-color-success-rgb: 28, 135, 69;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #19773d;
	--ion-color-success-tint: #339358;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #e71d38;
	--ion-color-danger-rgb: 231, 29, 56;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #cb1a31;
	--ion-color-danger-tint: #e9344c;

	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	--ion-color-medium: #6d7079;
	--ion-color-medium-rgb: 109, 112, 121;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #60636a;
	--ion-color-medium-tint: #7c7e86;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}

/*
* Dark Colors
* -------------------------------------------
*/

body.dark {
	--ion-color-primary: #478aff;
	--ion-color-primary-rgb: 71, 138, 255;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0, 0, 0;
	--ion-color-primary-shade: #3e79e0;
	--ion-color-primary-tint: #5996ff;

	--ion-color-secondary: #009bb2;
	--ion-color-secondary-rgb: 0, 155, 178;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #00889d;
	--ion-color-secondary-tint: #1aa5ba;

	--ion-color-tertiary: #7a7fff;
	--ion-color-tertiary-rgb: 122, 127, 255;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0, 0, 0;
	--ion-color-tertiary-shade: #6b70e0;
	--ion-color-tertiary-tint: #878cff;

	--ion-color-success: #21a152;
	--ion-color-success-rgb: 33, 161, 82;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #1d8e48;
	--ion-color-success-tint: #37aa63;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255, 196, 9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0, 0, 0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #ed5569;
	--ion-color-danger-rgb: 237, 85, 105;
	--ion-color-danger-contrast: #000000;
	--ion-color-danger-contrast-rgb: 0, 0, 0;
	--ion-color-danger-shade: #d14b5c;
	--ion-color-danger-tint: #ef6678;

	--ion-color-dark: #222428;
	--ion-color-dark-rgb: 34, 36, 40;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #1e2023;
	--ion-color-dark-tint: #383a3e;

	--ion-color-medium: #8c8e97;
	--ion-color-medium-rgb: 140, 142, 151;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0, 0, 0;
	--ion-color-medium-shade: #7b7d85;
	--ion-color-medium-tint: #9899a1;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}

/*
* Material Design Dark Theme
* -------------------------------------------
*/

.md body.dark {
	--ion-background-color: #000000;
	--ion-background-color-rgb: 18, 18, 18;

	--ion-text-color: #ffffff;
	--ion-text-color-rgb: 255, 255, 255;

	--ion-border-color: #222222;

	--ion-color-step-50: #1e1e1e;
	--ion-color-step-100: #2a2a2a;
	--ion-color-step-150: #363636;
	--ion-color-step-200: #414141;
	--ion-color-step-250: #4d4d4d;
	--ion-color-step-300: #595959;
	--ion-color-step-350: #656565;
	--ion-color-step-400: #717171;
	--ion-color-step-450: #7d7d7d;
	--ion-color-step-500: #898989;
	--ion-color-step-550: #949494;
	--ion-color-step-600: #a0a0a0;
	--ion-color-step-650: #acacac;
	--ion-color-step-700: #b8b8b8;
	--ion-color-step-750: #c4c4c4;
	--ion-color-step-800: #d0d0d0;
	--ion-color-step-850: #dbdbdb;
	--ion-color-step-900: #e7e7e7;
	--ion-color-step-950: #f3f3f3;

	--ion-item-background: transparent;

	--ion-toolbar-background: #000000;

	--ion-tab-bar-background: #000000;

	--ion-card-background: #000000;
}
